// On Search Page, Hotel View Links must correlate to the Search Parameters

const setupHotelViewlLinks = ($) => {
	// Add Hotel View Links
	const params = new URLSearchParams(window.location.search);
	$('a[href*="/hotel/?id="]').each(function () {
		const $this = $(this);
		const href = $this.attr("href");
		const elParams = new URLSearchParams(href);
		if (params.get("q")) {
			// More for search persistance
			elParams.set("q", params.get("q"));
		}
		if (params.get("check_in")) {
			elParams.set("check_in", params.get("check_in"));
		}
		if (params.get("check_out")) {
			elParams.set("check_out", params.get("check_out"));
		}
		if (params.get("check_in_out")) {
			elParams.set("check_in_out", params.get("check_in_out"));
		}
		if (params.get("adults")) {
			elParams.set("adults", params.get("adults"));
		}
		if (params.get("children")) {
			elParams.set("children", params.get("children"));
		}
		if (params.get("child_ages")) {
			elParams.set("child_ages", params.get("child_ages"));
		}
		if (params.get("sc")) {
			elParams.set("sc", params.get("sc"));
		}
		$this.attr("href", decodeURIComponent(elParams.toString()));
	});
};

export default function setupSearchPage($) {
	setupHotelViewlLinks($);
	$(document).arrive('a[href*="/hotel/?id="]', function () {
		setupHotelViewlLinks($);
	});
}
