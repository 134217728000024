import isEmpty from "lodash/isEmpty";

export default class Signals {
	static init(user) {
		if (isEmpty(user)) {
			return;
		}
		const { id, email, ...properties } = user;
		if (typeof window.heap !== "undefined") {
			window.heap.identify(id);
			window.heap.addUserProperties({ email, ...properties });
		}
		if (typeof window.FS !== "undefined") {
			// Setup FullStory identity
			window.FS.identify(id, {
				displayName: user.name,
				email,
				...properties
			});
		}
		if (typeof window.hj !== "undefined") {
			// Setup Hotjar identity
			window.hj("identify", id, {
				displayName: user.name,
				email,
				...properties
			});
		}
		if (typeof window.mixpanel !== "undefined") {
			window.mixpanel.identify(id);
			window.mixpanel.people.set({
				$email: email,
				USER_ID: id,
				...properties
			});
		}
	}

	static track(eventName, properties) {
		if (typeof window.mixpanel !== "undefined") {
			window.mixpanel.track(eventName, properties);
		}
	}
}
