export default function setupReadmore($) {
	var nInitialCount = 160;
	var moretext = "more >";
	var lesstext = "less";
	$(".read-more-text").each(function () {
		var paraText = $(this).html();
		if (paraText.length > nInitialCount) {
			var sText = paraText.substr(0, nInitialCount);
			var eText = paraText.substr(
				nInitialCount,
				paraText.length - nInitialCount
			);
			var newHtml =
				sText +
				'...<span class="moretext"><span>' +
				eText +
				'</span><a href="#" class="moretext-links">' +
				moretext +
				"</a></span>";
			$(this).html(newHtml);
		}
	});

	$(".moretext-links").on("click", function (e) {
		var lnkHTML = $(this).html();
		if (lnkHTML == lesstext) {
			$(this).html(moretext);
		} else {
			$(this).html(lesstext);
		}
		$(this).prev().toggle();
		e.preventDefault();
	});
}
