/* eslint-disable camelcase, no-console */

// Welcome to Javscript Entry.

import '@/scss/style.scss';

import ready from "document-ready";
import "arrive";
import 'flowbite';

import { setupChildrenFormHandler } from "./children-form";
import Signals from "./signals";
import setupSearchPage from "./search";
import setupHotelPage from "./hotel";
import setupCheckout from "./checkout";
import setupDatePicker from "./datepicker";
import setupBackButton from "./back-button";
import setupAutocomplete from "./autocomplete";
import setupNumberSpinner from "./number-spinner";
import setupReadmore from "./readmore";
import setupQuickSearch from "./quick-search";
import setupTextEllipsis from "./text-ellipsis";
import {RocketPreloadLinks} from "./wp-rocket-adapter/preload-links";


// Identify user.
if (typeof site_user !== "undefined") {
	if (site_user.id && site_user.id !== "0") {
		Signals.init(site_user);
	}
}
window.identifyUserForSignals = (signalData = {}) => {
	const { id } = signalData;
	if (id) {
		Signals.init(signalData);
	}

	if (window.usersForSignals.length > 0) {
		window.usersForSignals.forEach((data) => {
			Signals.init(data);
		});
	}
};
window.usersForSignals = window.usersForSignals || [];
window.identifyUserForSignals();

// Manage Browser Based visibility
ready(() => {
	(async ($) => {
		setupSearchPage($);
		setupHotelPage($);

		const formSel = '.jet-form[action*="jet_engine_action=book"]';
		setupChildrenFormHandler($(formSel), $);
		$(document).arrive(formSel, function () {
			setupChildrenFormHandler($(this), $);
		});

		setupBackButton($);
		setupAutocomplete($);
		setupDatePicker($);
		setupNumberSpinner($);
		setupReadmore($);
		setupQuickSearch($);
		setupTextEllipsis();

		RocketPreloadLinks.run()

		// Setup Checkout
		setupCheckout($);

		// Setup Search Bars
		const $searchBars = $("#home-search-section, #header-search-bar");
		$searchBars.each(function () {
			const $searchBar = $(this);
			if ($searchBar.find('input[name="adults"]').length) {
				$searchBar.find('input[name="adults"]').gokindaNumberSpinner();
			}
			if ($searchBar.find('input[name="children"]').length) {
				$searchBar.find('input[name="children"]').gokindaNumberSpinner();
			}
		});

		// Prevent remove events from anchors.
	})(window.jQuery);
});
