export default function setupNumberSpinner($) {
	$.fn.gokindaNumberSpinner = function () {
		return this.each(function () {
			var inputElm = $(this);

			if (inputElm.parent(".gk-spinner").length === 0) {
				inputElm
					.wrap('<div class="gk-spinner"></div>')
					.before($("<span>-</span>").addClass("gk-spin gk-spin-minus"))
					.after($("<span>+</span>").addClass("gk-spin gk-spin-plus"));
			} else {
				return;
			}

			inputElm.next(".gk-spin-plus").on("click", function (e) {
				e.preventDefault();
				var currentVal = inputElm.val() || 0;
				currentVal = parseInt(currentVal);
				inputElm.val(currentVal + 1);
			});

			inputElm.prev(".gk-spin-minus").on("click", function (e) {
				e.preventDefault();
				var currentVal = inputElm.val() || 0;
				currentVal = parseInt(currentVal);
				if (currentVal > 0) {
					inputElm.val(currentVal - 1);
				} else {
					inputElm.val(0);
				}
			});
		});
	};
}
