import URL from "url-parse";

export default function quickSearch($) {
	$(".quick-search-btn .elementor-widget-container").on("click", function () {
		this.classList.toggle("active");
		$("#quick-search-section").toggleClass("active");

		setTimeout(function () {
			var $inputEl = $("#quick-search-section").find(
				'input[name="check_in_out"]'
			);
			var inputSearch = $("#quick-search-section").find('input[name="q"]');
			if (inputSearch.length) {
				inputSearch.gokindaAutocomplete();
			}

			if ($(".search-bar").find('input[name="adults"]').length) {
				$(".search-bar").find('input[name="adults"]').gokindaNumberSpinner();
			}

			if ($(".search-bar").find('input[name="children"]').length) {
				$(".search-bar").find('input[name="children"]').gokindaNumberSpinner();
			}

			if ($inputEl.length) {
				const url = new URL(window.location.href, true);
				var qsCheckInOut = url.query.check_in_out;
				var datePickerParams = {
					mode: "range",
					minDate: "today",
					dateFormat: "Y-m-d",
					altInput: true,
					altFormat: "j M y",
					appendTo: $inputEl.get(0),
					static: true,
					showMonths: 2,
					shorthandCurrentMonth: true,
					locale: {
						rangeSeparator: " - "
					},
					onParseConfig: function (selectedDates, dateStr, instance) {
						if (window.innerWidth <= 599) {
							var wrapper = instance.element.closest("div");
							wrapper.classList.add("flatpickr-overflow-hidden");
						}
					},
					onReady: function (selectedDates, dateStr, instance) {
						if (window.innerWidth <= 599) {
							var wrapper = instance.element.closest("div.flatpickr-wrapper");
							if (wrapper) {
								var scrollWrapper = document.createElement("div");
								scrollWrapper.classList.add("flatpickr-scroll-wrapper");
								scrollWrapper.appendChild(instance.calendarContainer);
								wrapper.appendChild(scrollWrapper);
								scrollWrapper.style.height = "1px";
							}
							var overflowHiddenEl = instance.element.closest(
								"div.flatpickr-overflow-hidden"
							);
							overflowHiddenEl.classList.remove("flatpickr-overflow-hidden");
						}
					},
					onOpen: function (selectedDates, dateStr, instance) {
						var wrapper = instance.element.closest("div.flatpickr-wrapper");
						if (wrapper) {
							var scrollWrapper = wrapper.querySelector(
								".flatpickr-scroll-wrapper"
							);
							if (scrollWrapper !== null) {
								scrollWrapper.style.height = "300px";
							}
						}
					},
					onClose: function (selectedDates, dateStr, instance) {
						var wrapper = instance.element.closest("div.flatpickr-wrapper");
						if (wrapper) {
							var scrollWrapper = wrapper.querySelector(
								".flatpickr-scroll-wrapper"
							);
							if (scrollWrapper !== null) {
								scrollWrapper.style.height = "1px";
							}
						}
					}
				};
				if (qsCheckInOut) {
					datePickerParams.defaultDate = qsCheckInOut.split(" - ");
				}

				window.flatpickr($inputEl.get(0), datePickerParams);
			}
		}, 500);
	});
}
