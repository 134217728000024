export default function setupAutocomplete($) {
	$.fn.gokindaAutocomplete = function () {
		return this.each(function () {
			var inputElm = $(this);
			var searchQuery = "";

			var debounce = function (func, wait, immediate) {
				var timeout;
				return function () {
					var context = this,
						args = arguments;
					var later = function () {
						timeout = null;
						if (!immediate) {
							func.apply(context, args);
						}
					};
					var callNow = immediate && !timeout;
					clearTimeout(timeout);
					timeout = setTimeout(later, wait);
					if (callNow) {
						func.apply(context, args);
					}
				};
			};

			var ajaxSearch = debounce(function () {
				var query = inputElm.val();

				if (query === "" || searchQuery.trim() === query.trim()) {
					return;
				}

				inputElm.parent().find(".search_autocomplete_wrapper").hide();
				inputElm.parent().find(".autocomplete_loading").show();

				//empty cordinates for new values
				$('input[name="lat"]').val("");
				$('input[name="lng"]').val("");

				inputElm
					.parent()
					.nextAll()
					.find('button[type="submit"]')
					.attr("disabled", true);

				$.ajax({
					type: "post",
					url: "/wp-admin/admin-ajax.php",
					data: { key: query, action: "get_search_autocomplete" }
				})
					.done(function (res) {
						searchQuery = query;
						inputElm
							.parent()
							.find(".search_autocomplete_wrapper")
							.empty()
							.html(res)
							.show();
					})
					// .always(function (data, status) {
					.always(function () {
						inputElm.parent().find(".autocomplete_loading").hide();
						inputElm
							.parent()
							.nextAll()
							.find('button[type="submit"]')
							.attr("disabled", false);
					});
			}, 300);

			inputElm
				.parent()
				.append(
					$('<span class="autocomplete_loading">searching...</span>').css({
						display: "none",
						position: "absolute",
						left: "25px",
						bottom: "-10px",
						"font-size": "12px"
					})
				)
				.append(
					$('<div class="search_autocomplete_wrapper"><div>').css({
						display: "none",
						position: "absolute",
						top: "50px",
						left: "0",
						"z-index": "99999"
					})
				);
			inputElm.on("keyup", function () {
				debounce(ajaxSearch(), 250);
			});

			inputElm.on("click", function () {
				if (
					!inputElm.parent().find(".search_autocomplete_wrapper").is(":empty")
				) {
					inputElm.parent().find(".search_autocomplete_wrapper").show();
				}
			});

			$(document).on("click", ".location, .hotel", function () {
				var value = $(this).data("location");
				var lat = $(this).data("lat");
				var lng = $(this).data("lng");
				inputElm.val(value);
				inputElm
					.parents(".jet-form-row")
					.nextAll()
					.find('input[name="lat"]')
					.val(lat);
				inputElm
					.parents(".jet-form-row")
					.nextAll()
					.find('input[name="lng"]')
					.val(lng);
				$(".search_autocomplete_wrapper").hide();
			});

			$(document).on("mouseup", function (e) {
				var dropPanel = inputElm.parent().find(".search_autocomplete_wrapper");
				if (
					(!dropPanel.is(e.target) && dropPanel.has(e.target).length === 0) ||
					(inputElm.is(e.target) && inputElm.has(e.target).length === 0)
				) {
					dropPanel.hide();
				}
			});
		});
	};

	if ($('input[name="q"]').length) {
		//Disable autocomplete default browser feature
		$('input[name="q"]').attr("autocomplete", "off");
		$('input[name="q"]').gokindaAutocomplete();
	}
}
