import Url from "url-parse";
import { easepick, RangePlugin, LockPlugin, AmpPlugin } from "@easepick/bundle";
import JQueryStatic from "jquery";

export default function setupDatePicker($: JQueryStatic) {
	$('input[type="text"][name="check_in_out"]').each(function () {
		const $el = $(this);

		const currentUrl = new Url(window.location.href, true);
		const { check_in_out } = currentUrl.query;
		const [startDate, endDate] = (check_in_out ?? "").split(" - ");

		new easepick.create({
			element: $el.get(0) as HTMLElement,
			grid: 2,
			calendars: 2,
			zIndex: 99,
			plugins: [RangePlugin, LockPlugin, AmpPlugin],
			css: [
				"https://cdn.jsdelivr.net/npm/@easepick/bundle@1.2.1/dist/index.css"
			],
			RangePlugin: {
				tooltip: true,
				tooltipNumber(num) {
					return num - 1;
				},
				locale: {
					one: "night",
					other: "nights"
				},
				startDate,
				endDate
			},
			LockPlugin: {
				minDate: new Date(),
				inseparable: true
			},
			AmpPlugin: {
				dropdown: {
					years: true,
					months: true
				},
				weekNumbers: false,
				darkMode: false
			}
		});
	});
}
