// Setup Hotel Page

export default function setupHotelPage($) {
	// Setup Room Accordion
	$(".accordion__title").on("click", function(e) {
		e.preventDefault();
		var $this = $(this);

		if (!$this.hasClass("accordion-active")) {
			$(".accordion__content").slideUp(400);
			$(".accordion__title").removeClass("accordion-active");
			$(".accordion__arrow").removeClass("accordion__rotate");
		}

		$this.toggleClass("accordion-active");
		$this.next().slideToggle();
		$(".accordion__arrow", this).toggleClass("accordion__rotate");
	});

	window.htmx.onLoad(() => {
		// Setup Room Policy Modal
		$(".policy-action-link").on("click", function(event) {
			event.preventDefault();
			var $this = $(this);
			$(".policy-modal")
				.css({
					display: "flex"
				})
				.find(".policy-modal__body")
				.html($this.data("policy-detail"));
		});

		$(".policy-modal__close-btn").on("click", function(event) {
			event.preventDefault();
			$(".policy-modal").css({
				display: "none"
			});
		});

		$(".policy-modal").on("mouseup", function(e) {
			var elem = $(".policy-modal__container");
			if (!elem.is(e.target) && elem.has(e.target).length === 0) {
				$(this).hide();
			}
		});
	});
}
