// On Search Bar, Children Selection yields a form popup

import Swal from "sweetalert2";

export function setupChildrenFormHandler($form, $) {
	// On submit, check children age
	$form.on("submit", function (e) {
		var $form = $(this);
		const $childAges = $form.find('input[name="child_ages"]');
		const $children = $form.find('input[name="children"]');
		const $location = $form.find('input[name="q"]');
		const $latitude = $form.find('input[name="lat"]');
		const $longitude = $form.find('input[name="lng"]');

		const childAgesVal = $childAges.val();
		const childrenVal = $children.val();
		const locationVal = $location.val();
		const latitudeVal = $latitude.val();
		const longitudeVal = $longitude.val();

		if (locationVal == "" || latitudeVal == "" || longitudeVal == "") {
			//reset to empty
			$location.val("");
			$latitude.val("");
			$longitude.val("");

			var parent = $location.parent("div");
			parent.css({ backgroundColor: "#ffc8d9" }).show();
			setTimeout(function () {
				parent.css({ backgroundColor: "" });
			}, 2000);

			//show autocomplete panel to inidicate user to select location or hotel
			if ($location.parent().find(".search_autocomplete_wrapper").length) {
				$location.parent().find(".search_autocomplete_wrapper").show();
			}
			return false;
		}

		//add trigger handler. mainly used in autocomplete.js script to validate location field
		if (childrenVal > 0 && !childAgesVal) {
			e.preventDefault();
			e.stopPropagation();
			console.log("Setup Children Form Handler:", {
				childrenVal,
				childAgesVal
			});
			const options = ["<option value default>Age needed</option>"];
			for (let i = 0; i < 18; i += 1) {
				options.push('<option value="' + i + '">' + i + " years old</option>");
			}
			const inputs = [];
			for (let i = 0; i < childrenVal; i += 1) {
				const index = i + 1;
				inputs.push(
					'<select id="swal-input' +
						index +
						'" class="swal2-select" style="margin: 0 0 10px 0 !important;">' +
						options.join("") +
						"</select>"
				);
			}

			Swal.fire({
				title: "Confirm Children Ages",
				html: inputs.join(""),
				focusConfirm: false,
				preConfirm: function () {
					const resp = [];
					for (let i = 0; i < childrenVal; i += 1) {
						const index = i + 1;
						const v = document.getElementById("swal-input" + index).value;
						if (v) {
							resp.push(v);
						}
					}
					return resp;
				}
			}).then(function (resp) {
				const value = resp.value;
				if (value && Array.isArray(value)) {
					if (parseInt(value.length, 10) === parseInt(childrenVal, 10)) {
						$form.find('input[name="child_ages"]').val(value);

						$form.submit();
					}
				}
			});

			return false;
		}

		return true;
	});
}
