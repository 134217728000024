export default function setupTextEllipsis() {
	var headings = document.querySelectorAll("h2.heading-ellipsis");

	headings.forEach(function (el) {
		var originalNodes = document.createDocumentFragment();
		while (el.firstChild) {
			var words = el.removeChild(el.firstChild);
			words.textContent.match(/\s?\w+\s?/g).forEach(function (word) {
				originalNodes.appendChild(document.createTextNode(word));
			});
		}
		el.appendChild(document.createTextNode(String.fromCharCode(8230)));

		var currentHeight = el.getBoundingClientRect().height;

		var lines = 0;
		while (originalNodes.childNodes.length > 0 && lines < 2) {
			el.insertBefore(
				originalNodes.removeChild(originalNodes.firstChild),
				el.lastChild
			);
			if (el.getBoundingClientRect().height > currentHeight) {
				currentHeight = el.getBoundingClientRect().height;
				lines++;
			}
		}
		if (lines === 2) {
			el.removeChild(el.lastChild.previousSibling);
			el.lastChild.previousSibling.textContent =
				el.lastChild.previousSibling.textContent.trim();
		} else {
			el.removeChild(el.lastChild);
		}
	});
}
